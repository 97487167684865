@import (less) "normalize.css";
@import (less) "../node_modules/bootstrap/dist/css/bootstrap.css";
@import (less) "../node_modules/fullpage.js/dist/fullpage.css";
@import (less) "../js/vendor/fullpage.js/dist/jquery.fullpage.min.css";

@import (less) "../recaptcha/recaptcha.css";

@import (less) "savi-core.less";

@import (less) "variables.less";
@import (less) "menu-horizontal.less";
@import (less) "slider.less";
@import (less) "dropkick.less";
@import (less) "form.less";

@font-face {
    font-family: 'untitled_sansregular';
    src: url('/fonts/untitledsans-regular-webfont.woff2') format('woff2'),
         url('/fonts/untitledsans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.text {
    font-family: 'untitled_sansregular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
}

a[href^="x-apple-data-detectors:"] {
    color: @core-color__default;
    text-decoration: none;
}
a[href^="tel"], a[href^="sms"] {
    color: @core-color__default;
    text-decoration: none;
}

html, body {
    margin: 0;
    padding: 0;
    background-color: #000000;
    .text();
}

.rsvp-link,
.submit {
    .text();

    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    border-left:none;
    border-right:none;
    line-height: 3em;
    display: inline-block;
    padding: 0 1em;

    background:none;
    outline:none;


    &:hover {
        color:#fff;
        text-decoration:none;
    }
}

body {
    font-size: 17px;
}

p {
    margin-bottom: 14px;
}
 
*, a {
    &:focus {
        outline: 0 none;
    }
}

a, img {
    border:none;
    outline:none
}
.col-centered{
    // need important for ie8
    float: none !important;
    margin: 0 auto;
}

iframe[name='google_conversion_frame'] {
    height: 0 !important;
    width: 0 !important;
    line-height: 0 !important;
    font-size: 0 !important;
    margin-top: -13px;
    float: left;
}

.reg-form {
    max-width: 100%;
}

.hide-div {
    display: none;
}

.invisible-text {
    color:  #000000;
}

.contents-wrapper {
    position: relative;
    line-height: 17px;
    margin: 0 auto;
    width: 50vw;
    height: 100vh;
    background-color: #000000;
}

.ascii-text-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    line-height: 17px;
    font-size: 17px;
}

.bg-dots {

    animation: animate-dots 0.7s linear infinite;
}

.bg-stars {
    animation: animate-stars 0.7s linear infinite;
}

.invite-page {
    // background-image: url('/img/bg2.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #000000;
}

@keyframes animate-dots{
    0%, 100%{ opacity: 1; }
    25%, 75%{ opacity: 0.5; }
    50% { opacity: 0; }
}

@keyframes animate-stars {
    0%, 100% { opacity: 0;}
    25%, 75% { opacity: 0.5; }
    50%{ opacity: 1; }
}

@import (less) "home.less";
@import (less) "thankyou.less";


  // md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
    
}

// xs styles
@media (max-width: 767px) {
    .contents-wrapper {
        width: 100vw;
    }
    body {
        font-size: 14px;
    }
    .reg-form {
        max-width: 100%;
    }
}