html {
    height: 100%;
}
.thankyou {
    text-align: center;
    display: flex;
    width: 100%;
    height: 100%;
    padding-bottom: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
        font-size: 17px;
    }
}

@media (max-width: 767px) {
   
}
