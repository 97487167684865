@labelCol: #FFFFFF;

@inputCol: #FFFFFF;
@backgroundCol: transparent;

@formFontSize: 16px;

@submitCol: #FFFFFF;
@submitBg: #FFFFFF;

@borderCol: @inputCol;

.border {
  margin-top: 10px;
}
.form-holder {
  width: 350px;
  float: left;
}
.error {
  color: red !important;
}
.form-title {
  width: 100%;
  color: @labelCol;
  height: 33px;
  padding: 7px 4px 0px 6px;
  margin-bottom: 2px;
}
.text-input,
.custom-select,
.custom-submit,
.custom-textarea {
  border-radius: 0px;
  appearance: none;
  height: 35px;
  width: 100%;
  border: 0;
  font-size: @formFontSize;
  .mono();
  letter-spacing: 0.05em;
  background-color: @backgroundCol;
  color: @inputCol;
  outline: none;
  border-bottom: 1px solid #FFFFFF;
  border-style: dashed;
  margin-top: 15px;
  text-transform: uppercase;
  text-align: center;
}

.reg-form { 
  text-align: center;
}

.form-heading {
  margin-top: 70px;
}

.custom-textarea {
  height: 100px;
}

.custom-select {
  padding-top: 4px;
  height: 38px;
  box-sizing: border-box;
  background: url("../img/core/arrow.png") 97% 50% no-repeat @backgroundCol;
}

.custom-submit {
  color: #F4887C;
  text-decoration: underline;
  box-shadow: none;
  font-size: @formFontSize;
  text-align: center;
  padding: 15px 15px;
  width: 150px;
  border: none;
  height: auto;
  line-height:1;
  margin-top: 5px;
}

.arrow {
  width: 34px;
  margin: 0 auto;
  margin-top: 70px;
}

label.radio {
	border: 1px solid #000000;
	box-sizing: border-box;
	display: block;
	height: 100%;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
	padding: 15px;
	cursor: pointer;
	opacity: .5;
	transition: all .5s ease-in-out;
	&:hover, &:focus, &:active {
		border: 1px solid darken(#000000, 50%);
	}
}
input[type="radio"] {
	opacity: 0;
	width: 0;
  height: 0;
}

input[type="radio"]:active ~ label {
  //opacity: 1;
}

input[type="radio"]:checked ~ label {
  //opacity: 1;
	border: none;
}

.radio-wrapper {
  margin-left: -25px;
}


.form-container {
  width: 80%;
  margin: 0 auto;
}
.form-container h4 {
  color: #fff;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 20px;
}
label {
  color: @labelCol;
  font-weight: normal;
}
::placeholder {
  color: #767676;
}
option {
  color: #000000 !important;
}

.customSelectInner {
    width: 100% !important;
}

.radio-yes, .radio-no {
  span {
    color: #000000;
  }
}

.radio-active {
  span {
    color: #FFFFFF;
  }
}

.form-field-left-half, .form-field-right-half, .form-field-full {
  float: left;
  width: 50%;
  height: 50px;
}
.form-field-right-half {
  float: right;

}
.form-field-full {
  width: 100%;
}
.form-field{
  width: 400px;
  margin: 0 auto;
}

.form-back-wrapper {
  margin-top: 50px;
  a {
    .mono();
    color: #000000;
    text-decoration: none;
    border-bottom: 1px #000000 solid;
  }
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {

}

// xs styles
@media (max-width: 767px) {
  .form-field {
    width: 250px;
  }
  .form-heading {
    margin-top: 20px;
  }
}
