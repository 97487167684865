// FONTS
@font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-serif:       Georgia, "Times New Roman", Times, serif;
@font-family-monospace:   Monaco, Menlo, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;
@mono:                    Courier, "Courier New", monospace;

// COLOURS
@core-color__default:     #000000;

@core-dropkick-bg:        #d9d9d2;
@core-dropkick-col:       #000000;

.serif {
    font-family: 'Spectral SC', serif;
}
.sans {
    font-family: 'Anton', sans-serif;
}

.mono {
    font-family: @mono;
}

@green: #e0eddb;