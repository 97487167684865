body {
    //padding-bottom: 19vh;
}

.header-text {
    width: 50vmin;
    margin: 0 auto;
    svg {
        width: 100%;
        height: 100%;
    }
}

.invite-msg {
    text-align: center;
    position: relative;
    width: 100%;
}

.invite-msg__top {
    padding-bottom: 90px;
    width: 100%;
    padding-left: 9px;
    padding-right: 18px;
    line-height: 19px;
    span {
        background-color: #000000;
    }
}

.svg-wrapper {
    left: 0;
    right: 0;
    margin: 0 auto;
}

.french {
    width: 500px;
    position: absolute;
    bottom: 50px;
}

.noel {
    width: 500px;
    position: absolute;
    top: 50px;
}

.invite-msg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.star-wrapper__top {
    padding-bottom: 90px;
}

.star-wrapper__mid {
    padding-bottom: 90px;
}

.star-wrapper__bottom {
    position: relative;
    padding-top: 90px;
    width: 100%;
}

.invite-msg__mid {
    position: relative;
    padding-bottom: 90px;
    width: 100%;
    padding-left: 0px;
    padding-right: 10px;
    line-height: 24px;
    span {
        background-color: #000000;
    }
}

.invite-msg__bottom {
    position: relative;
    padding-bottom: 90px;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    a {
        text-decoration: none;
        color: #F4887C;
        text-decoration: underline;
        padding-left: 2px;
        padding-right: 7px;
        line-height: 18px;
    }
}

@media (max-width: 767px) {
    .header-text {
        width: 70vmin;
    }

    .up-arrow, .down-arrow {
        padding: 13px 7px;
        margin-bottom: 10px;

        .arrow {
            width: 24px;
            height: 12px;
        }
    }
}

@media (max-width: 667px) and (max-height: 900px) {
    .header-text {
        width: 80vmin;
    }

    .invite-msg__top {
        padding-bottom: 25px;
    }
    
    .star-wrapper__top {
        margin-top: 25px;
        padding-bottom: 25px;
    }
    
    .star-wrapper__mid {
        padding-bottom: 25px;
    }
    
    .star-wrapper__bottom {
        padding-top: 25px;
    }
    
    .invite-msg__mid {
        padding-bottom: 25px;
    }
    
    .invite-msg__bottom {
        padding-bottom: 25px;
    }
    .french {
        width: 200px;
    }
    
    .noel {
        width: 200px;
    }
}
